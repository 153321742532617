main {
  z-index: 999;
  position: relative;
}

#item-list {
  display: flex;
  flex-wrap: wrap;
  gap: .5em;

  .device {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 1.2em;
    padding: .5em .7em;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: rgba(0,0,0,0.5) 0 0 0.2em;
    transition: background-color 500ms linear;
    flex: 1 0 40%;

    .row {
      display: flex;
      flex-direction: column;
    }

    h2 {
      font-size: 1.4em;
      padding: 0;
      margin: 0;
    }

    &.green {
      background-color: var(--color-light-green);
    }

    &.yellow {
      background-color: var(--color-light-yellow);
    }

    &.orange {
      background-color: var(--color-light-orange);
    }

    &.red {
      background-color: var(--color-light-red);
    }

    &.gray {
      background-color: var(--color-light-gray);
    }
  }
}