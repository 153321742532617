:root {
  --color-light-red: #fc5c65;
  --color-red: #eb3b5a;
  --color-light-orange: #fd9644;
  --color-orange: #fa8231;
  --color-light-yellow: #fed330;
  --color-yellow: #f7b731;
  --color-light-green: #26de81;
  --color-green: #20bf6b;

  --color-light-blue: #45aaf2;
  --color-blue: #2d98da;
  --color-dark-blue: #4b7bec;
  --color-darker-blue: #3867d6;
  --color-darkest-blue: #2a518e;
  --color-light-purple: #a55eea;
  --color-purple: #8854d0;

  --color-white: #ffffff;
  --color-light-gray: #fbfbfb;
  --color-gray: #a5b1c2;
  --color-dark-gray: #778ca3;
  --color-black: #4b6584;
}