@import "Colors";

body {
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  background: var(--color-darkest-blue);

  &.running {
    background: var(--color-green);
  }

  &.error {
    background: var(--color-red);
  }

  main {
    padding: 1em;
    background: white;
    min-height: 100%;
    border: 1px solid var(--color-dark-gray);
    border-radius: 1em 1em 0 0;
  }
}
