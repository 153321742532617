body {

  #live-button {
    cursor: pointer;
    width: 100%;
    font-size: 1.6em;
  }

  &.live {
    #live-button {
      background-color: var(--color-green);
    }
  }

  &.error {
    background-color: var(--color-red);
  }
}